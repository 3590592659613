<template>
  <div>
    <section>
      <div class="container sell-bg">
        <div class="row">
          <div class="col-lg-6 col-md-8 ms-auto">
            <h1 class="mb-2 text-start">SELL WITH US</h1>
            <p>Unlock the true value of your luxury property by entrusting it to our distinguished team. With bespoke
              marketing strategies, unrivalled local knowledge and exclusive clientele, we ensure that your property
              attracts discerning buyers and gets the attention that it deserves.</p>
            <div class="col-12 mt-lg-5 py-3 text-sm-end text-center">
              <a class="btn btn-primary" style="background: #12305b; border: 1px solid #fff;" href="/contact" role="button">Contact us</a>
            </div>
          </div>
        </div>
        <!-- <a class="social-links switch-bg-dblue mb-0 cta-link" id="lifestyle-cta" href="/article" >READ MORE</a> -->
      </div>
    </section>
  </div>
</template>

<script>

export default {
  data() {
    return {
      property: {

      }
    };
  },
};
</script>