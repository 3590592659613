<template>
  <div>
    <section>
      <div class="container">
        <div class="row justify-content-center">

          <div class="col-lg-7 text-center mb-5">
            <h1>LIFESTYLE</h1>
            <p>Immerse yourself in the art of indulgence, where luxury living takes centre stage.<br> Discover secluded
              holiday destinations, exquisite wines, the finest restaurants, private events, flawless interior decorating,
              inspiring landscaping designs and more. Embrace the extraordinary and delve into a lavish lifestyle.</p>
          </div>

          <div class="col-12 pb-5">
            <div class="p-3 bg-white rounded">
              <div class="row">
                <div class="col-lg-5 col-md-6 p-3">
                  <div class="h-100">
                    <img src="/images/lifestyle/steyn-city.jpg" style="border-bottom-right-radius: 30px;"
                    width="" height="" class="mw-100" alt="Contemporary art with a local twist" />
                  </div>
                </div>
                <div class="col-lg-7 col-md-6 pe-lg-5 pt-4">
                  <h3>The Gallery at Steyn City: Contemporary art with a local twist</h3>
                  <p>When The Gallery at Steyn City opened its doors on 22 March 2023, the Johannesburg art scene was blessed with a prestigious exhibit of exquisite artwork and a new home for art collectors, investors and enthusiasts.</p>
                </div>
              </div>
            </div>
            <a class="social-links switch-bg-dblue mb-0 cta-link" href="/article/the-gallery-steyn-city"
              id="lifestyle-cta">READ MORE</a>
          </div>

          <div class="col-12 pb-5">
            <div class="p-3 bg-light rounded">
              <div class="row">
                <div class="col-lg-5 col-md-6 p-3">
                  <div class="h-100">
                    <img src="/images/lifestyle/10-must-have-features.jpg" style="border-bottom-right-radius: 30px;"
                    width="" height="" class="mw-100" alt="10 must-have features for luxury homes" />
                  </div>
                </div>
                <div class="col-lg-7 col-md-6 pe-lg-5 pt-4">
                  <h3>10 must-have features for luxury homes</h3>
                  <p>Certain features are standards in every home, such as a bedroom and a kitchen. However, when you enter the realm of luxury living, high-end and sophisticated features become the hallmark of what it means to truly live the lifestyle.</p>
                </div>
              </div>
            </div>
            <a class="social-links switch-bg-dblue mb-0 cta-link" href="/article/10-must-have-features"
              id="lifestyle-cta">READ MORE</a>
          </div>

          <div class="col-12 pb-5">
            <div class="p-3 bg-white rounded">
              <div class="row">
                <div class="col-lg-5 col-md-6 p-3">
                  <div class="h-100">
                    <img src="/images/lifestyle/blog-polo.png" style="border-bottom-left-radius: 30px;"
                      class="mw-100" width="" height="" alt="Which South African equestrian estate should you invest in?"/>
                  </div>
                </div>
                <div class="col-lg-7 col-md-6 pe-lg-5 pt-4">
                  <h3>Which South African equestrian estate should you invest in?</h3>
                  <p>South Africa is a country that offers many interesting property investment opportunities for local and overseas buyers. One of those lucrative opportunities is our beautiful and vast selection of equestrian estates.</p>
                </div>
              </div>
            </div>
            <a class="social-links switch-bg-dblue mb-0 cta-link" href="/article/south-african-equestrian-estates"
              id="lifestyle-cta">READ MORE</a>
          </div>

          <div class="col-12">
            <div class="p-3 bg-light rounded">
              <div class="row">
                <div class="col-lg-5 col-md-6 p-3">
                  <div class="h-100">
                    <img src="/images/lifestyle/blog-cape-peninsula.jpg" style="border-bottom-left-radius: 30px;" width="" height="" class="mw-100" alt="Exploring the Cape Peninsula from Hout Bay"/>
                  </div>
                </div>
                <div class="col-lg-7 col-md-6 pe-lg-5 pt-4">
                  <h3>5 Weekend Getaways: Exploring the Cape Peninsula from Hout Bay</h3>
                  <p>Hout Bay has become a popular destination for property semigration, with many new families having
                    relocated from the likes of Johannesburg and Pretoria over the last few years. And it's easy to
                    understand why. Nestled between the mountains and the Atlantic Ocean, it offers more than stunning
                    beachfront properties and a tranquil lifestyle. It is a gateway to some of the most breathtaking
                    weekend getaways in the Cape Peninsula. For those who live or are considering moving to Hout Bay, the
                    peninsula presents a treasure trove of adventures just a short drive away.</p>
                </div>
              </div>
            </div>
            <a class="social-links switch-bg-dblue mb-0 cta-link" href="/article/exploring-the-cape-peninsula" id="lifestyle-cta">READ MORE</a>
          </div>

          <div class="col-12 mt-4">
            <div class="p-3 bg-light rounded">
              <div class="row">
                <div class="col-lg-5 col-md-6">
                  <div class="h-100">
                    <img src="https://fusionstoragelive.blob.core.windows.net/images/Listing/Office,801896/Photos,52211/pic_52211165.jpg" style="border-top-right-radius: 30px;" width="" height="" class="mw-100" alt="Spotlight on Plettenberg Bay"/>
                  </div>
                </div>
                <div class="col-lg-7 col-md-6 pe-lg-5 pt-4">
                  <h3>Spotlight on Plettenberg Bay</h3>
                  <p>Did you know that Plettenberg Bay (or simply Plett, as most South Africans call it) is one of the
                    five most expensive places to buy luxury property in the whole of Africa? According to the latest
                    research by Henley & Partners and New World Wealth, Cape Town is the most expensive place for Africa's
                    super-wealthy to acquire a prime 200sqm to 400sqm apartment (at an average of around R100 000/sqm),
                    followed by Plett and Hermanus, tied at around R42 000/sqm for prime apartments, and then by Umhlanga
                    and Marrakech in Morocco, tied at R38 000/sqm.</p>
                </div>
              </div>
            </div>
            <a class="social-links switch-bg-dblue mb-0 cta-link" href="/article/spotlight-on-plettenberg-bay" id="lifestyle-cta">READ MORE</a>
          </div>

          <div class="col-12 mt-4">
            <div class="p-3 bg-light rounded">
              <div class="row">
                <div class="col-lg-5 col-md-6">
                  <div class="h-100">
                    <img src="https://fusionstoragelive.blob.core.windows.net/images/Listing/Office,801877/Photos,55037/pic_55037049.jpg" style="border-top-right-radius: 30px;"  width="" height="" class="mw-100" alt="Short term real-estate investments"/>
                  </div>
                </div>
                <div class="col-lg-7 col-md-6 pe-lg-5 pt-4">
                  <h3>Short term real-estate investments</h3>
                  <p>Investing in real estate has long been recognised as a safe strategy to generate revenue. Short-term real
                estate investments, in particular, have gained popularity in the past few years due to their flexible
                nature and higher earning potential.</p>
                </div>
              </div>
            </div>
            <a class="social-links switch-bg-dblue mb-0 cta-link" href="/article/short-term-real-estate-investments-exploring-the-potential-of-airbnb-house-flipping"  id="lifestyle-cta">READ MORE</a>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  data() {
    return {
      property: {

      }
    };
  },
};
</script>