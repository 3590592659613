<template>
  <div>
    <section class="pt-5">
      <div class="container mt-5">
        <div class="row">
          <div class="col-12 ms-auto">
            <h1 class="">QUICK LINKS</h1>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="quick-links">
              <h4>BUY - PROVINCE</h4>
              <ul class="quick-links-list">
                <li><a href="#">Buy property in Eye of Africa</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="quick-links">
              <h4>BUY - CITY</h4>
              <ul class="quick-links-list">
                <li><a href="#">Buy property in Cape Town</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="quick-links">
              <h4>RENT - PROVINCE</h4>
              <ul class="quick-links-list">
                <li><a href="#">Rent property in Cape Town</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="quick-links">
              <h4>RENT - CITY</h4>
              <ul class="quick-links-list">
                <li><a href="#">Rent property in Cape Town</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  data() {
    return {
      property: {

      }
    };
  },
};
</script>]