<template>
  <div>
	<Menu />
	<section id="banner" class="my-5">
      <div class="container">
        <div class="row justify-content-center g-0">
          <div class="col-12">
            <div class="banner-section" id="home-banner">
              <img src="/images/banners/home-banner.jpg" alt="luxury property and lifestyle banner" class="w-100" width="" height=""/>
            </div>
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-8 pt-5 text-center">
                <h3>Choose a price range</h3>
                <p>
                  <span class="mx-2">R 10 million</span>
                  <span class="mx-2">to</span>
                  <span class="mx-2">R {{ maxPrice / 1000000 }} million</span>
                  <input type="range" class="form-range" v-model="maxPrice" :min="12000000" :max="100000000" :step="1000000" />
                </p>
              </div>
            </div>
            <div class="row">
              <center><h3 class="pt-5 pb-2">Choose your lifestyle</h3></center>
              <div class="col-12 text-center">
                <button class="btn btn-primary filter-btn filterOne" :class="{ 'active': selectedType === 'buy' }" 
                  @click="toggleType('buy')">Buy</button>
                <button class="btn btn-primary filter-btn filterOne" :class="{ 'active': selectedType === 'rent' }"
                  @click="toggleType('rent')">Rent</button>
                <button class="btn btn-primary filter-btn filterTwo" :class="{ 'active': selectedLocations.includes('inland') }"
                  @click="toggleLocation('inland')">Inland</button>
                <button class="btn btn-primary filter-btn filterTwo" :class="{ 'active': selectedLocations.includes('coastal') }"
                  @click="toggleLocation('coastal')">Coastal</button>
                <button class="btn btn-primary filter-btn filterTwo" :class="{ 'active': selectedLocations.includes('countryside') }"
                  @click="toggleLocation('countryside')">Countryside</button>
                <button class="btn btn-primary filter-btn filterTwo" :class="{ 'active': selectedLocations.includes('city') }"
                  @click="toggleLocation('city')">City</button>
              </div>
            </div>
            <center><router-link to="/portfolio#top" class="dropdown-item"><button v-on:click="updateStorePrice()" class="btn btn-primary mt-5">Show matching properties</button></router-link></center>
          </div>
        </div>
      </div>
    </section>
    <FeaturedProperty />
    <Search />
    <LifestyleFeed />
    <SellWithUs />
    <div class="mb-5"></div>
    <Footer />
  </div>
</template>
<script>
import Menu from '@/components/home/Menu.vue'
import FeaturedProperty from '@/components/home/FeaturedProperty.vue'
import Search from '@/components/home/Search.vue'
import LifestyleFeed from '@/components/home/LifestyleFeed.vue'
import SellWithUs from '@/components/home/SellWithUs.vue'
import SeoLinks from '@/components/home/SeoLinks.vue'
import Footer from '@/components/home/Footer.vue'
export default {
  components: {
    Menu, FeaturedProperty, Search, LifestyleFeed, SellWithUs, SeoLinks, Footer
  },
  mounted() {
  },
  data() {
    return {
      listings: [],
      selectedType: '',
      selectedLocations: [],
      maxPrice: 150000000,
      minPrice: 10000000,
      highToLow: true
    }
  },
  methods: {
    toggleType(type) {
      if (type === 'buy' || type === 'rent') {
        this.$store.commit('setFilter', { filter: 'buy', value: type === 'buy' });
        this.$store.commit('setFilter', { filter: 'rent', value: type === 'rent' });
        this.selectedType = this.selectedType === type ? null : type;
        this.$store.commit('setMaxPrice', this.maxPrice);
      }
    },
    toggleLocation(location) {
      const index = this.selectedLocations.indexOf(location);
      if (index !== -1) {
        this.selectedLocations.splice(index, 1);
      } else {
        this.selectedLocations.push(location);
      }
      this.$store.commit('setFilter', { filter: location, value: !this.$store.state.search.tags[location] });
      this.$store.commit('setMaxPrice', this.maxPrice);
    },
    updateStorePrice() {
      console.log('updating')
      this.$store.commit('setMaxPrice', this.maxPrice);
    }
  }
}
</script>